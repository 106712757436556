import React from 'react';
import Layout from "../components/Layout";
import NavOne from "../components/NavOne";
import PageHeader from "../components/PageHeader";
import Footer from "../components/Footer";
import Celebrations from '../components/Celebrations';
import Topbar from '../components/Topbar';

const CelebrationsPage = () => {
    return (
        <Layout pageTitle="Shanti Asiatic School | Celebrations">
            <Topbar />
            <NavOne />
            <PageHeader title="Celebrations" />
            <Celebrations />
            <Footer />
        </Layout>
    );
};

export default CelebrationsPage;
