import React, { useState, useEffect  } from "react";
import http from '../_api/http';
import teamd1 from "../assets/images/Celebrations.png";

const Celebrations = () =>  {
  
  const [loading, setLoading] = useState(true);
  const [imageDescs, setImageDesc] = useState([]);
  const PUBLIC_URL = process.env.REACT_APP_APIURL;
  useEffect(() => {
    http.get('api/imageupload/ApiImageUploadList?image_subcategory_id=11&section=Celebrations').then((res) => {
      if (res.data.status === 200) {
        setImageDesc(res.data.image);
        setLoading(false);
      }
    });
  }, []);
    return (
        <>
        <section className="team-details">
        <div className="container">
          <div className="row justify-content-between">
          {loading
              ? <h4>View Celebration Loading...</h4>
              :
              <>
                  {imageDescs.map((item, i) =>
                  <div className="row" key={i}> 
                    <div className="col-lg-6" key={i}>
                    <div className="team-one__single">
                      <div className="team-one__image">
                        <img src={PUBLIC_URL + "uploadimage/" + item.image} alt="" />
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="team-details__content">
                      <h2 className="team-details__title title">{item.image_title}</h2>
                      <p className="team-details__text">
                      {item.image_description}
                      </p>
                    </div>
                  </div>
                  </div>
                  )}
                
              </>
            }
          </div>
        </div>
      </section>
    </>
    );
  }


export default Celebrations;
